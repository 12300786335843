<template>
  <div class="row">
    <div :class="['d-none d-lg-block col-lg-1 pl-3', paddingTopClass ]">
      <img :src="iconImgSrc" />
    </div>
    <div class="col">
      <div class="row" v-for="(feature, idx) in features" :key="idx">
        <div class="col-6 text-right">
          <i :class="feature.labelIconClass" :style="{ color: feature.labelIconColor }" v-if="feature.labelIconClass"></i>
          <label class="mb-0">{{ feature.label }}</label>

        </div>
        <div class="col-6 pt-1">
          <span class="score" v-if="feature.type || 'text' === 'text'">{{ feature.value }}</span>
          <router-link class="score" v-if="feature.type === 'link'" :to="feature.link">{{ feature.linkText }} &raquo;</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    features: Array,
    iconName: String,
  },
  computed: {
    iconImgSrc() {
      return require(`Images/${this.iconName}`);
    },
    paddingTopClass() {
      return `pt-${this.features.length - 1}`
    }
  },
};
</script>

<style scoped lang="scss">
label {
  font-size: 1rem;
  font-family: $bold-font-family;
  color: #162b32;
  font-weight: 700;
}
.score {
  color: #3b9bf5;
  font-family: "Nunito Sans ExtraBold", "Nunito Sans Bold", "Nunito Sans",
    sans-serif;
  font-size: 1rem;
  font-weight: 800;
}

i {
  padding-right: 5px;
  font-size: 1.1rem;
}
</style>
